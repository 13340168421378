// importing actions

import { FETCH_RECORDS, FETCH_INDIVIDUALS } from "../../types";

// state
const INITIAL_STATE = {
  records: [],
  individuals: [],
};

// Redux reducer function
const recordReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload } = action;

  switch (type) {
    case FETCH_RECORDS:
      return {
        ...state,
        records: payload,
      };
    case FETCH_INDIVIDUALS:
      return {
        ...state,
        individuals: payload,
      };
    default:
      return state;
  }
};

export default recordReducer;
