/* 
@example

    export const ACTION_NAME = "ACTION_NAME"
*/

/*

  @Authentications/User
*/

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const PASSWORD_CHANGE = "PASSWORD_CHANGE";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";

/* export const SIGNUP_VERIFICATION_SUCCESS = "SIGNUP_VERIFICATION_SUCCESS";
export const PASSWORD_REQUEST_SUCCESS = "PASSWORD_REQUEST_SUCCESS"; */
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_ONE_USER_SUCCESS = "GET_ONE_USER_SUCCESS";
/* export const LOCK_USER_SUCCESS = "LOCK_USER_SUCCESS";
export const UPLOAD_PASSPORT_SUCCESS = "UPLOAD_PASSPORT_SUCCESS"; */ 

/*

@Record

*/
export const FETCH_RECORDS = "FETCH_RECORDS";

export const FETCH_INDIVIDUALS = "FETCH_INDIVIDUALS";

/*

@Report

*/
export const FETCH_TOP_PERFORMERS = "FETCH_TOP_PERFORMERS";
export const FETCH_REPORT_SUMMARY = "FETCH_REPORT_SUMMARY";
export const FETCH_OWNER_INFO = "FETCH_OWNER_INFO";
export const FETCH_OWNER_TRANSACTIONS = "FETCH_OWNER_TRANSACTIONS";


/*

@Transaction

*/
export const FETCH_ALL_TRANSACTIONS = "FETCH_ALL_TRANSACTIONS";

/*

@Dashboard

*/
export const FETCH_ACTUAL_YEAR = "FETCH_ACTUAL_YEAR";
export const FETCH_ACTUAL_QUARTER = "FETCH_ACTUAL_QUARTER";
export const FETCH_ACTUAL_MONTH = "FETCH_ACTUAL_MONTH";
export const FETCH_ACTUAL_DAY = "FETCH_ACTUAL_DAY";
export const FETCH_VAT_BY_HOUR = "FETCH_VAT_BY_HOUR";
export const FETCH_ACTUAL_HOUR = "FETCH_ACTUAL_HOUR";
export const FETCH_YEARLY_SUMMARY = "FETCH_YEARLY_SUMMARY";
export const FETCH_YEARLY_VAT = "FETCH_YEARLY_VAT";
export const FETCH_MONTHLY_SUMMARY = "FETCH_MONTHLY_SUMMARY";
export const FETCH_ALL_TRANSACTIONS_DASHBOARD = "FETCH_ALL_TRANSACTIONS_DASHBOARD";

/*

@Capital Market

*/

export const FETCH_DAILY_DATA = "FETCH_DAILY_DATA";
export const FETCH_WEEKLY_DATA = "FETCH_WEEKLY_DATA";
export const FETCH_MONTHLY_DATA = "FETCH_MONTHLY_DATA";
export const FETCH_QUARTERLY_DATA = "FETCH_QUARTERLY_DATA";
export const FETCH_YEARLY_DATA = "FETCH_YEARLY_DATA";
export const FETCH_ALL_TRANSACTIONS_CAPITAL = "FETCH_ALL_TRANSACTIONS_CAPITAL";
export const FETCH_MARKET_SEGMENT_CAPITAL = "FETCH_MARKET_SEGMENT_CAPITAL";
export const FETCH_MONTHLY_PERFORMANCE_CAPITAL = "FETCH_MONTHLY_PERFORMANCE_CAPITAL";
export const FETCH_SUMMARY_CAP = "FETCH_SUMMARY_CAP";

/*

@Insurance

*/

export const FETCH_DAILY = "FETCH_DAILY";
export const FETCH_WEEKLY = "FETCH_WEEKLY";
export const FETCH_MONTHLY = "FETCH_MONTHLY";
export const FETCH_QUARTERLY = "FETCH_QUARTERLY";
export const FETCH_YEARLY = "FETCH_YEARLY";
export const FETCH_ALL_TRANSACTIONS_INSURANCE = "FETCH_ALL_TRANSACTIONS_INSURANCE";
export const FETCH_MARKET_SEGMENT_INSURANCE = "FETCH_MARKET_SEGMENT_INSURANCE";
export const FETCH_MONTHLY_PERFORMANCE_INSURANCE = "FETCH_MONTHLY_PERFORMANCE_INSURANCE";
export const FETCH_SUMMARY_INS = "FETCH_SUMMARY_INS";

/*

@Capital Market sub

*/

// SEC
export const FETCH_DAILY_SEC = "FETCH_DAILY_SEC";
export const FETCH_WEEKLY_SEC = "FETCH_WEEKLY_SEC";
export const FETCH_MONTHLY_SEC = "FETCH_MONTHLY_SEC";
export const FETCH_QUARTERLY_SEC = "FETCH_QUARTERLY_SEC";
export const FETCH_YEARLY_SEC = "FETCH_YEARLY_SEC";
export const FETCH_TRANSACTIONS_CAP_SEC = "FETCH_TRANSACTIONS_CAP_SEC";
export const FETCH_SEC_VAT_BY_HOUR = "FETCH_SEC_VAT_BY_HOUR";
export const FETCH_SEC_QUARTERLY_DATA = "FETCH_SEC_QUARTERLY_DATA";

// CSCS
export const FETCH_DAILY_CSCS = "FETCH_DAILY_CSCS";
export const FETCH_WEEKLY_CSCS = "FETCH_WEEKLY_CSCS";
export const FETCH_MONTHLY_CSCS = "FETCH_MONTHLY_CSCS";
export const FETCH_QUARTERLY_CSCS = "FETCH_QUARTERLY_CSCS";
export const FETCH_YEARLY_CSCS = "FETCH_YEARLY_CSCS";
export const FETCH_TRANSACTIONS_CAP_CSCS = "FETCH_TRANSACTIONS_CAP_CSCS";
export const FETCH_CSCS_VAT_BY_HOUR = "FETCH_CSCS_VAT_BY_HOUR";
export const FETCH_CSCS_QUARTERLY_DATA = "FETCH_CSCS_QUARTERLY_DATA";

// NGX
export const FETCH_DAILY_NGX = "FETCH_DAILY_NGX";
export const FETCH_WEEKLY_NGX = "FETCH_WEEKLY_NGX";
export const FETCH_MONTHLY_NGX = "FETCH_MONTHLY_NGX";
export const FETCH_QUARTERLY_NGX = "FETCH_QUARTERLY_NGX";
export const FETCH_YEARLY_NGX = "FETCH_YEARLY_NGX";
export const FETCH_TRANSACTIONS_CAP_NGX = "FETCH_TRANSACTIONS_CAP_NGX";
export const FETCH_NGX_VAT_BY_HOUR = "FETCH_NGX_VAT_BY_HOUR";
export const FETCH_NGX_QUARTERLY_DATA = "FETCH_NGX_QUARTERLY_DATA";

// FMDQ
export const FETCH_DAILY_FMDQ = "FETCH_DAILY_FMDQ";
export const FETCH_WEEKLY_FMDQ = "FETCH_WEEKLY_FMDQ";
export const FETCH_MONTHLY_FMDQ = "FETCH_MONTHLY_FMDQ";
export const FETCH_QUARTERLY_FMDQ = "FETCH_QUARTERLY_FMDQ";
export const FETCH_YEARLY_FMDQ = "FETCH_YEARLY_FMDQ";
export const FETCH_TRANSACTIONS_CAP_FMDQ = "FETCH_TRANSACTIONS_CAP_FMDQ";
export const FETCH_FMDQ_VAT_BY_HOUR = "FETCH_FMDQ_VAT_BY_HOUR";
export const FETCH_FMDQ_QUARTERLY_DATA = "FETCH_FMDQ_QUARTERLY_DATA";

/*

@Insurance sub

*/

// NAICOM
export const FETCH_DAILY_NAICOM = "FETCH_DAILY_NAICOM";
export const FETCH_WEEKLY_NAICOM = "FETCH_WEEKLY_NAICOM";
export const FETCH_MONTHLY_NAICOM = "FETCH_MONTHLY_NAICOM";
export const FETCH_QUARTERLY_NAICOM = "FETCH_QUARTERLY_NAICOM";
export const FETCH_YEARLY_NAICOM = "FETCH_YEARLY_NAICOM";
export const FETCH_TRANSACTIONS_INS_NAICOM = "FETCH_TRANSACTIONS_INS_NAICOM";
export const FETCH_NAICOM_VAT_BY_HOUR = "FETCH_NAICOM_VAT_BY_HOUR";
export const FETCH_NAICOM_QUARTERLY_DATA = "FETCH_NAICOM_QUARTERLY_DATA";

// PENSION
export const FETCH_DAILY_PENSION = "FETCH_DAILY_PENSION";
export const FETCH_WEEKLY_PENSION = "FETCH_WEEKLY_PENSION";
export const FETCH_MONTHLY_PENSION = "FETCH_MONTHLY_PENSION";
export const FETCH_QUARTERLY_PENSION = "FETCH_QUARTERLY_PENSION";
export const FETCH_YEARLY_PENSION = "FETCH_YEARLY_PENSION";
export const FETCH_TRANSACTIONS_INS_PENSION = "FETCH_TRANSACTIONS_INS_PENSION";
export const FETCH_PENSION_VAT_BY_HOUR = "FETCH_PENSION_VAT_BY_HOUR";
export const FETCH_PENSION_QUARTERLY_DATA = "FETCH_PENSION_QUARTERLY_DATA";
