import { Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";

// layouts
import AppLayout from "../layouts/AppLayout/AppLayout.layout";

// config
import { DEFAULT_PATH } from "../config";
import Loader from "../components/Loader/Loader.component";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <AppLayout />,
      children: [
        { element: <Navigate to={DEFAULT_PATH} replace />, index: true },
        { path: "dashboard", element: <Dashboard /> },
        // Capital Market
        {
          path: "capital-market",
          element: <CapitalMarket />,
        },
        {
          path: "capital-market/sec",
          element: <SEC />,
        },
        {
          path: "capital-market/ngx",
          element: <NGX />,
        },
        {
          path: "capital-market/Stock-brokers",
          element: <FMDQ />,
        },
        {
          path: "capital-market/cscs",
          element: <CSCS />,
        },
        // Insurance
        {
          path: "insurance",
          element: <Insurance />,
        },
        {
          path: "insurance/naicom",
          element: <Naicom />,
        },
        {
          path: "insurance/pension",
          element: <Pension />,
        },
        // Reports
        {
          path: "report",
          element: <Report />,
        },
        {
          path: "report/:trx_id",
          element: <OwnerInfo />,
        },

        // Contact
        {
          path: "contact",
          element: <Contact />,
        },
        {
          path: "contact/individual",
          element: <Individual />,
        },
        {
          path: "contact/company",
          element: <Company />,
        },

        // Users
        {
          path: "users",
          element: <Users />,
        },
        {
          path: "users/add-user",
          element: <Signup />,
        },

        { path: "*", element: <Page404 /> },
        // { path: "*", element: <Navigate to='/404' replace /> },
      ],
    },
    { path: "*", element: <Page404 /> },

    // AUth
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "reset-password",
      element: <ResetPass />,
    },
    {
      path: "change-password",
      element: <ChangePass />,
    },
  ]);
}

const Dashboard = Loadable(lazy(() => import("../pages/Dashboard")));

// Capital Market
const CapitalMarket = Loadable(lazy(() => import("../pages/CapitalMarket")));
const SEC = Loadable(lazy(() => import("../pages/CapitalMarket/SEC")));
const NGX = Loadable(lazy(() => import("../pages/CapitalMarket/NGX")));
const FMDQ = Loadable(lazy(() => import("../pages/CapitalMarket/FMDQ")));
const CSCS = Loadable(lazy(() => import("../pages/CapitalMarket/CSCS")));

// Insurance
const Insurance = Loadable(lazy(() => import("../pages/Insurance")));
const Naicom = Loadable(lazy(() => import("../pages/Insurance/Naicom")));
const Pension = Loadable(lazy(() => import("../pages/Insurance/Pension")));

// Report
const Report = Loadable(lazy(() => import("../pages/reports/Reports.page")));
const OwnerInfo = Loadable(
  lazy(() => import("../pages/reports/ownerInfo/OwnerInfo.page"))
);

// contact
const Contact = Loadable(lazy(() => import("../pages/Record/Record.page")));
const Individual = Loadable(
  lazy(() => import("../pages/Record/Individual.page"))
);
const Company = Loadable(lazy(() => import("../pages/Record/Company.page.")));

// Users
const Users = Loadable(lazy(() => import("../pages/Users")));
const Signup = Loadable(lazy(() => import("../pages/Users/signup")));

// Auth
const Login = Loadable(lazy(() => import("../pages/Auth/Login/Login.page")));
const ChangePass = Loadable(
  lazy(() => import("../pages/Auth/Password/passwordReset"))
);
const ResetPass = Loadable(lazy(() => import("../pages/Auth/ForgotPassword")));

const Page404 = Loadable(lazy(() => import("../pages/Page404/Page404.page")));
