// importing actions

import {
  FETCH_OWNER_INFO,
  FETCH_OWNER_TRANSACTIONS,
  FETCH_REPORT_SUMMARY,
  FETCH_TOP_PERFORMERS,
} from "../../types";

// state
const INITIAL_STATE = {
  performers: [],
  reportSummary: {},
  ownerInfo: {},
  ownerTransactions: [],
};

// Redux reducer function
const reportReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload } = action;

  switch (type) {
    case FETCH_TOP_PERFORMERS:
      return {
        ...state,
        performers: payload,
      };
    case FETCH_REPORT_SUMMARY:
      return {
        ...state,
        reportSummary: payload,
      };
    case FETCH_OWNER_INFO:
      return {
        ...state,
        ownerInfo: payload,
      };
    case FETCH_OWNER_TRANSACTIONS:
      return {
        ...state,
        ownerTransactions: payload,
      };
   
    default:
      return state;
  }
};

export default reportReducer;
